import axios from "axios";
import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import { createLogger } from "redux-logger";
import { handleRequests } from "@redux-requests/core";
import { createDriver } from '@redux-requests/axios';
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext } from "redux-first-history";
import { unstable_batchedUpdates } from "react-dom";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import { globalHistory } from "@reach/router";
import { memoize as _memoize, merge as _merge } from "lodash";

import { hydrateReducer } from "../../../../common/redux/hydrate/reducer";
import {
  transformLayoutData,
  transformSiteConfigData,
  transformBuildInfoData,
  transformDatabagData,
  transformApiData,
} from "../../../../common/providers/contentstack/modular-blocks";

const reachGlobalHistory = globalHistory;
const batch = unstable_batchedUpdates;

const ssr = typeof window === "undefined";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: ssr ? createMemoryHistory() : createBrowserHistory(),
    reachGlobalHistory: reachGlobalHistory,
    savePreviousLocations: 5,
    batch: batch,
  });

// @todo: need to memoize
export const configureStore = () => {
  const middlewares = [];

  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(axios)
  });

  middlewares.push(thunk);
  middlewares.push(...requestsMiddleware);
  middlewares.push(routerMiddleware);

  if (typeof window !== "undefined") {
    const loggerMiddleware = createLogger({
      collapsed: true,
    });
    middlewares.push(loggerMiddleware);
  }

  const combinedReducers = combineReducers({
    hydrate: hydrateReducer,
    requests: requestsReducer,
    router: routerReducer,
  });

  return { middlewares, combinedReducers };
};

// only the first argument is used in memoization
const createReduxStore = (cacheKey, pathname, data) => {
  const { middlewares, combinedReducers } = configureStore();
  const composeEnhancers =
    (typeof window !== "undefined" && composeWithDevTools) || compose;
  const createStoreWithMiddlewares = composeEnhancers(
    applyMiddleware.apply(this, middlewares)
  )(createStore);

  const hydrate = {};
  
  if (data?.pageContext?.content) {

    if (data.pageContext.__type === 'apiData') {
      hydrate.layoutData = transformApiData(data.pageContext.content);
    }
    else {
      hydrate.layoutData = transformLayoutData(data.pageContext.content);
    }
    
    hydrate.siteConfigData = transformSiteConfigData(data.pageContext.site_config);
    hydrate.buildInfoData = transformBuildInfoData(data.pageContext.build_info);
    hydrate.databagData = transformDatabagData(data.pageContext.databag);
  }

  // @todo: gross
  const state = {
    hydrate: hydrate,
    router: {
      location: {
        pathname: pathname,
        search: "",
        hash: "",
        state: null,
        key: "default",
      },
      action: null,
      previousLocations: [],
    },
  };

  const initialReduxState = {
    hydrate: {
      layoutData: {},
      siteConfigData: {},
      databagData: {},
    },
    requests: {
      queries: {},
      mutations: {},
      cache: {},
      downloadProgress: {},
      uploadProgress: {},
      requestsKeys: {},
      normalizedData: {},
      ssr: [],
      watchers: {},
      websocket: {
        pristine: true,
        connected: false,
      },
    },
    router: {
      location: {
        pathname: null,
        search: "",
        hash: "",
        state: null,
        key: "default",
      },
      action: null,
      previousLocations: [],
    },
  };
  const initialState = _merge(initialReduxState, state);
  const store = createStoreWithMiddlewares(combinedReducers, initialState);
  return { store };
};

export const getReduxStore = !ssr
  ? _memoize(createReduxStore)
  : createReduxStore;

export const getHistory = (cacheKey, pathname) => {
  const store = getReduxStore("init", pathname).store;
  return createReduxHistory(store);
};

export const getBrowserHistory = !ssr ? _memoize(getHistory) : getHistory;
