import React from "react";
import PropTypes from "prop-types";
import { decode } from "html-entities";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { usePostHog } from "posthog-js/react";

import "@reach/skip-nav/styles.css";
import "../styles/layout.css";
import FontUrl from "../fonts/new-order-font/new-order-medium.otf";

const Layout = React.memo((props) => {
  const currentTheme = {
    project: "hedy",
  };

  const pageContext = props.children?.props.pageContext;
  const posthog = usePostHog();

  // no site config here means we're in Gatsby dev mode error
  if (!pageContext || !pageContext.site_config) {
    return (
      <ThemeProvider theme={currentTheme}>
        <SkipNavLink />
        <Helmet htmlAttributes={{ lang: "en" }} encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <link
            rel="preload"
            as="font"
            href={FontUrl}
            type="font/otf"
            crossOrigin="anonymous"
          />
        </Helmet>

        <SkipNavContent />

        <div>{props.children}</div>
      </ThemeProvider>
    );
  }

  const title =
    pageContext.content &&
    pageContext.content.seo &&
    pageContext.content.seo.meta_title &&
    pageContext.content.seo.meta_title.length > 0
      ? decode(pageContext.content.seo.meta_title)
      : decode(pageContext.title);

  const desc =
    pageContext.content &&
    pageContext.content.seo &&
    pageContext.content.seo.meta_description &&
    pageContext.content.seo.meta_description.length > 0
      ? decode(pageContext.content.seo.meta_description)
      : decode(pageContext.site_config.seo.meta_description);

  const metaTitle = pageContext.site_config
    ? "Home" + " | " + decode(pageContext.site_config.seo.meta_title)
    : "";
  const metaDesc = pageContext.site_config ? desc : null;

  const metaRobots = pageContext.site_config
    ? decode(pageContext.site_config.seo.meta_robots)
    : null;

  return (
    <ThemeProvider theme={currentTheme}>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        encodeSpecialCharacters={false}
        title={`${metaTitle}`}
      >
        <meta charSet="utf-8" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content={`${metaDesc}`} />
        <meta name="robots" content={`${metaRobots}`} />

        <meta property="og:title" content={`${metaTitle}`} />
        <meta property="og:description" content={`${metaDesc}`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="418" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${metaTitle}`} />
        <meta name="twitter:site" content="" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:image:alt" content="" />
        <meta name="twitter:description" content={`${metaDesc}`} />

        <link
          rel="preload"
          as="font"
          href=""
          type="font/otf"
          crossOrigin="anonymous"
        />
      </Helmet>

      <div>
        <SkipNavContent />
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, { posthog })
        )}
      </div>
    </ThemeProvider>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.displayName = "Layout";

export default Layout;
