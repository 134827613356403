import * as React from "react";
import { Provider } from "react-redux";
import posthog from "posthog-js";

import { getReduxStore } from "./configureStore";
import App from "../components/App";
import GhubPostHogProvider from "@col-care/common/providers/posthog";

// const POSTHOG_API_KEY = "phc_bwA0EtQgj0Oe7prZHe2AgiuHYZIoUBqdM8VLdRuVhWy"; // test account
const POSTHOG_API_KEY = "phc_qZ0VvcnkkYm0OYMZdfd04fi6HXKJSmqRBY9GItnCtJF";

const reduxWrapper = (props, element) => {
  // this is memoized using both the 2nd and 3rd arguments
  const store = getReduxStore(
    "init",
    props.pathname,
    Object.assign({ posthog: posthog }, props)
  ).store;

  return (
    <GhubPostHogProvider
      apiKey={POSTHOG_API_KEY}
      posthog={posthog}
      posthogOptions={{
        autocapture: false,
      }}
    >
      <Provider store={store}>
        <App {...props} posthog={posthog}>
          {element}
        </App>
      </Provider>
    </GhubPostHogProvider>
  );
};

export default reduxWrapper;
