// import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "whatwg-fetch";

import reduxWrapper from "./src/state/reduxWrapper";

export const wrapPageElement = ({ props, element }) => {
  return reduxWrapper(props, element);
};
// other polyfills
smoothscroll.polyfill();

// framer hacks
// some of the hackiest crap i've ever written, ever. ever.
let listenerRef = null,
  menuToggle,
  menuToggleListenerAttached,
  lol = null;
const cb = (event) => {
  if (event.data.source === "ghub-framer-hedy") {
    if (event.data.payload.framerRendered) {
      // if (!window.ghub) { window.ghub = {} }
      window.ghub.framerRendered = true;
      var iframe = document.getElementById("hedy-source");
      if (iframe?.contentDocument) {
        if (!lol) {
          lol = setInterval(() => {
            const menu = iframe.contentDocument.querySelector(".framer-kgsh1t");
            if (menu != null) {
              const locationsNav =
                iframe.contentDocument.querySelector(".framer-kgsh1t");
              clearInterval(lol);
              lol = null;
              window.postMessage(
                {
                  source: "ghub-framer-hedy",
                  payload: {
                    framerMenuToggled: true,
                  },
                },
                "*"
              );
              window.ghub.hedy.menuOpen = true;
              if (locationsNav) {
                locationsNav.addEventListener("click", (e) => {
                  window.postMessage(
                    {
                      source: "ghub-framer-hedy",
                      payload: {
                        framerLocationsNav:
                          iframe.contentWindow.location.pathname,
                      },
                    },
                    "*"
                  );
                });
              }
            }
          }, 50);
        }

        menuToggle = iframe?.contentDocument.querySelector("#FVl8xD4kU");
        if (menuToggle) {
          if (!menuToggleListenerAttached) {
            menuToggle.addEventListener("click", (e) => {
              if (window.ghub.hedy.menuOpen) {
                if (!lol) {
                  lol = setInterval(() => {
                    const menu = document
                      .getElementById("hedy-source")
                      ?.contentDocument?.querySelector(".framer-kgsh1t");
                    if (menu != null) {
                      clearInterval(lol);
                      lol = null;
                      window.postMessage(
                        {
                          source: "ghub-framer-hedy",
                          payload: {
                            framerMenuToggled: true,
                          },
                        },
                        "*"
                      );
                      window.ghub.hedy.menuOpen = true;
                    }
                  }, 50);
                }
              }
              menuToggleListenerAttached = true;
              window.postMessage(
                {
                  source: "ghub-framer-hedy",
                  payload: {
                    framerMenuToggled: window.ghub.hedy.menuOpen,
                  },
                },
                "*"
              );

              const locationsNav =
                iframe.contentDocument.querySelector(".framer-kgsh1t");
              if (locationsNav) {
                locationsNav.addEventListener("click", (e) => {
                  window.postMessage(
                    {
                      source: "ghub-framer-hedy",
                      payload: {
                        framerLocationsNav:
                          iframe.contentWindow.location.pathname,
                      },
                    },
                    "*"
                  );
                });
              }
            });
          }
        }
      }
    }
    if (event.data.payload.menuToggled) {
      window.ghub.hedy.menuOpen = !window.ghub.hedy.menuOpen;
      window.postMessage(
        {
          source: "ghub-framer-hedy",
          payload: {
            framerMenuToggled: window.ghub.hedy.menuOpen,
          },
        },
        "*"
      );
    }
  }
};
if (!listenerRef) {
  listenerRef = window.addEventListener("message", cb, false);
}

// polyfills for Jane
if (!window.ResizeObserver) {
  window.ResizeObserver = require("resize-observer-polyfill");
}

if (!window.IntersectionObserver) {
  window.IntersectionObserver = require("intersection-observer-polyfill");
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const savedPosition = getSavedScrollPosition(location);
  window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 100);

  return false;
};
